import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { VersionText } from "src/app/components/VersionText";
import { SIDEBAR_TABS } from "../../constants";
import Menu from "../Menu";
import { SERVICES } from "./constants";
import { getUnreadAdminChatMessages } from "./services";
import "./styles.scss";


const SideBar = ({ reservationStatus }) => {
  const [user, setUser] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState(null);
  const { pathname } = useLocation();
  const [managementOpen, setManagementOpen] = useState(false);
  const { selectedProperty, isWsMessageReceived } = useSelector(
    (state) => state.reservations
  );
  const history = useHistory();

  useEffect(() => {
    if (
      pathname === "/reservations" ||
      pathname === "/extra-charges" ||
      pathname === "/users"
    )
      setManagementOpen(true);
  }, [pathname]);

  useEffect(() => {
    getUser();
    return () => { };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUnreadChatMessages();
    return () => { };
    // eslint-disable-next-line
  }, [selectedProperty]);

  useEffect(() => {
    getUnreadChatMessages();
    // eslint-disable-next-line
  }, [isWsMessageReceived]);

  const handleLogout = async () => {
    await Auth.signOut();

    history.push("/");
  };

  const getUser = async () => {
    const user = await Auth.currentUserInfo();
    if (user && user.hasOwnProperty("id")) {
      setUser(user);
    } else {
      handleLogout();
    }
  };

  const getUnreadChatMessages = async () => {
    const { apiEndpoint } = SERVICES.getUnreadAdminChatMessages;
    try {
      if (selectedProperty) {
        const response = await getUnreadAdminChatMessages(apiEndpoint, {
          propertyId: selectedProperty.value,
        });

        if (response) {
          setUnreadMessages(response.messages);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="sidebar-wrapper">
      <ul className="sidebar-menu">
        <li>
          <Menu
            title="Management"
            open={managementOpen}
            setOpen={setManagementOpen}
            style={{
              padding: "10.7px 16px 10px 20px",
            }}
          >
            <Menu.Option>
              <NavLink
                to={`/reservations?status=${reservationStatus}`}
                activeClassName="active"
              >
                <span className="menu-text">{SIDEBAR_TABS.reservations}</span>
              </NavLink>
            </Menu.Option>
            <Menu.Option>
              <NavLink to={`/extra-charges`} activeClassName="active">
                <span className="menu-text">{SIDEBAR_TABS.extraCharges}</span>
              </NavLink>
            </Menu.Option>
            {user ? (
              <Menu.Option>
                <NavLink to={`/users`} activeClassName="active">
                  <span className="menu-text">{SIDEBAR_TABS.users}</span>
                  {unreadMessages && unreadMessages > 0 && (
                    <span className="sidebar-icon-badge">{unreadMessages}</span>
                  )}
                </NavLink>
              </Menu.Option>
            ) : null}

            <Menu.Option>
              <NavLink to={`/import`} activeClassName="active">
                <span className="menu-text">{SIDEBAR_TABS.import}</span>
              </NavLink>
            </Menu.Option>
          </Menu>
        </li>
        {/* <li>
          <Menu
            title="HoodHubs"
            open={hoodhubsOpen}
            setOpen={setHoodhubsOpen}
            style={{
              padding: "10.7px 16px 10px 20px",
            }}
          >
            <Menu.Option>
              <NavLink to={`/geofencing`} activeClassName="active">
                <span className="menu-text">{SIDEBAR_TABS.geofencing}</span>
              </NavLink>
            </Menu.Option>
            <Menu.Option>
              <NavLink to={`/stores/dashboard`} activeClassName="active">
                <span className="menu-text">{SIDEBAR_TABS.stores}</span>
              </NavLink>
            </Menu.Option>
          </Menu>
        </li> */}

        <li>
          {/* {user && user.attributes["custom:role"] === "root" ? (
            <NavLink to={`/gooroo-booster`} activeClassName="active">
              <span className="menu-text">{SIDEBAR_TABS.goorooBooster}</span>
            </NavLink>
          ) : null} */}

          {user && (user.attributes["custom:role"] === "root" || user.attributes["custom:role"] === "super_admin") ? (
            <NavLink to={`/properties`} activeClassName="active">
              <span className="menu-text">{SIDEBAR_TABS.properties}</span>
            </NavLink>
          ) : null}
          {user && user.attributes["custom:role"] === "root" ? (
            <NavLink to={`/locks`} activeClassName="active">
              <span className="menu-text">{SIDEBAR_TABS.locks}</span>
            </NavLink>
          ) : null}
        </li>
        <VersionText />
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { reservationsCount, reservationStatus } = state.reservations;
  return {
    reservationsCount,
    reservationStatus,
  };
};

SideBar.propTypes = {
  reservationsCount: PropTypes.object.isRequired,
  reservationStatus: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(SideBar);
